import '../App.css';
import { Amplify } from 'aws-amplify';
import { StaticHeader, QuestionItemHead, QuestionItemCollectionSecond, QuestionItemFoot, Footer } from '../ui-components';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import { useState } from 'react';
import { useDataStoreCreateAction, useNavigateAction } from "@aws-amplify/ui-react/internal";
import { Qanswer } from "../models";
import { schema } from "../models/schema";

Amplify.configure(awsExports);

function CheckQuestion2() {
  let [PageNo, setPage] = useState(0);

  const navigateAction = useNavigateAction({ type: "url", url: "/CheckQuestion3" });
  const dataStoreCreateAction = useDataStoreCreateAction({
    fields: { PageNo: "2", AnsNo: PageNo },
    model: Qanswer,
    schema: schema,
  });

  const handleFrame30Click = () => {
    navigateAction();
    dataStoreCreateAction();
  };

  return (
    <div className="ContentsList">
      <StaticHeader />
      <QuestionItemHead 
        overrides={{
          "text39455121": {
            children:"次の内どの生徒にアプローチしたいですか"
          }
          }}/>
      <QuestionItemCollectionSecond
       //アイテムの値を取得する方法
        overrideItems={({ item, index}) => ({
          style: { cursor: "pointer"},
          onClick: () => setPage(item.SortNumber)
          })}
      />
      <QuestionItemFoot
        overrides={{
          "Frame 30": {
            onClick: handleFrame30Click, // ここで再利用する
          },
            "Frame 31": {
            onClick: useNavigateAction({ type: "url", url: "/CheckQuestion" })
            }
        }}
      />
      <Footer />
    </div>
  );
}

export default CheckQuestion2
