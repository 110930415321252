/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import StaticLogo from "./StaticLogo";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function StaticHeader(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="268px"
      direction="row"
      width="664px"
      height="80px"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
      padding="30px 30px 30px 30px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "StaticHeader")}
      {...rest}
    >
      <StaticLogo
        display="flex"
        gap="10px"
        direction="column"
        width="unset"
        height="27px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        color="main"
        {...getOverrideProps(overrides, "StaticLogo")}
      ></StaticLogo>
      <Icon
        width="27px"
        height="19px"
        viewBox={{ minX: 0, minY: 0, width: 27, height: 19 }}
        paths={[
          {
            d: "M0 19L27 19L27 15.8333L0 15.8333L0 19ZM0 11.0833L27 11.0833L27 7.91667L0 7.91667L0 11.0833ZM0 0L0 3.16667L27 3.16667L27 0L0 0Z",
            fill: "rgba(0,0,0,0.13)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "StaicMenuButton")}
      ></Icon>
    </Flex>
  );
}
