import '../App.css';
// === Add the following ===
import { Amplify } from 'aws-amplify';
import { ProductCard2,StaticHeader , ProductCard2Collection,Footer} from '../ui-components';
//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

import { Contents } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";

import { useSearchParams } from "react-router-dom";

import Comments from './Comments';



Amplify.configure(awsExports);
// ======
function ContentsPage() {
  
  const [searchParams] = useSearchParams();

  console.log("searchParams", searchParams)

  const articleId = searchParams.get('article_id');

  console.log("articleId", articleId)
  
  

  const itemsPagination ="";
  const contentItems = useDataStoreBinding({
    type: "collection",
    model: Contents,
    pagination: itemsPagination,
  }).items;
  
//   console.log(itemsDataStore);
 const contentItem = contentItems.find(i => i.id===articleId);

 console.log("contentItem",contentItem);


  return (
    <div className="ContentsList">
      <StaticHeader/>

      <ProductCard2 contents={ contentItem } />
      
      <Comments articleId={ contentItem?.id } />
      
      <Footer/>
    </div>
  );
}

export default ContentsPage;