/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function SelectStart(props) {
  const { overrides, ...rest } = props;
  const frameThreeZeroOnClick = useNavigateAction({
    type: "url",
    url: "./CheckQuestion",
  });
  return (
    <View
      width="664px"
      height="466px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SelectStart")}
      {...rest}
    >
      <Icon
        width="664px"
        height="48px"
        viewBox={{ minX: 0, minY: 0, width: 664, height: 48 }}
        paths={[
          {
            d: "M0 0L664 0L664 48L0 48L0 0Z",
            fill: "rgba(209,112,145,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="48px"
        left="0px"
        {...getOverrideProps(overrides, "Rectangle 24")}
      ></Icon>
      <Flex
        gap="88px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        position="absolute"
        bottom="47px"
        left="calc(50% - 315px - 0px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 31")}
      >
        <Text
          fontFamily="Inter"
          fontSize="30px"
          fontWeight="700"
          color="rgba(205,112,143,1)"
          lineHeight="22px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="あなたに合った教材や仲間をマッチングします"
          {...getOverrideProps(overrides, "text39193708")}
        ></Text>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-end"
          overflow="hidden"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(209,112,145,1)"
          borderRadius="20px"
          padding="21px 104px 21px 104px"
          backgroundColor="rgba(205,112,143,1)"
          onClick={() => {
            frameThreeZeroOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 30")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="22px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="診断開始"
            {...getOverrideProps(overrides, "text39193686")}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
