import { Input, Button, TextField, SelectField } from '@aws-amplify/ui-react';
import { StaticHeader, Footer } from '../ui-components';
import { API, Auth, Storage, graphqlOperation} from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import '../App.css';
import './CSS/Cafote.css';






function FileUploadComponent() {
  const [uploadedFilePath, setUploadedFilePath] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('アップロードする');
  const [title, setTitle] = useState('');
  const [abstract, setAbstract] = useState('');
  const [detail, setDetail] = useState('');
  const [categories, setCategories] = useState(0);
  const [subject, setSubject] = useState('');
  const [isSubjectEnabled, setIsSubjectEnabled] = useState(true);
  const [classLevel, setClassLevel] = useState(0);
  const [classPeople, setClassPeople] = useState(0);
  const [classStyle, setClassStyle] = useState(0);
  const [categoryname, setCategoryName] = useState('');
  const [subjectname, setSubjectName] = useState('');
  const [contentownername, setContentOwnerName] = useState()
  const [classlevelOptions, setClassLevelOptions] = useState([]);
  const [classpeopleOptions, setClassPeopleOptions] = useState([]);
  const [classstyleOptions, setClassStyleOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [meate, setMeate] = useState('');
  const [nerai, setNerai] = useState('');
  const [matome, setMatome] = useState('');

  useEffect(() => {
    const fetchClassLevelOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 1}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const classlevels = response.data.listQuetions.items;
        classlevels.sort((a, b) => a.SortNumber - b.SortNumber);
        console.log(classlevels);
        const updatedOptions = classlevels.map((classlevel) => ({
          value: classlevel.SortNumber,
          label: classlevel.QuetionItem
        }));
        setClassLevelOptions(updatedOptions);
        console.log('クラスレベルオプションの内容:', updatedOptions);
      } catch (error) {
      console.error('クラスレベルの取得エラー:',error);
      }
    };
    const fetchClassPeopleOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 3}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const classpeople = response.data.listQuetions.items;
        classpeople.sort((a, b) => a.SortNumber - b.SortNumber);
        console.log(classpeople);
        const updatedOptions = classpeople.map((classpeople) => ({
          value: classpeople.SortNumber,
          label: classpeople.QuetionItem
        }));
        setClassPeopleOptions(updatedOptions);
        console.log('クラス人数オプションの内容:', updatedOptions);
      } catch (error) {
      console.error('クラス人数の取得エラー:',error);
      }
    };
    const fetchClassStyleOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 5}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const classstyle = response.data.listQuetions.items;
        classstyle.sort((a, b) => a.SortNumber - b.SortNumber);
        //console.log(classstyle);
        const updatedOptions = classstyle.map((classstyle) => ({
          value: classstyle.SortNumber,
          label: classstyle.QuetionItem
        }));
        setClassStyleOptions(updatedOptions);
        console.log('クラス人数オプションの内容:', updatedOptions);
      } catch (error) {
      console.error('クラス人数の取得エラー:',error);
      }
    };
    const fetchCategoryOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 7}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const category = response.data.listQuetions.items;
        category.sort((a, b) => a.SortNumber - b.SortNumber);
        console.log(category);
        const updatedOptions = category.map((category) => ({
          value: category.SortNumber,
          label: category.QuetionItem
        }));
        setCategoryOptions(updatedOptions);
        console.log('カテゴリーの内容:', updatedOptions);
      } catch (error) {
      console.error('カテゴリーの取得エラー:',error);
      }
    };
    fetchClassLevelOptions();
    fetchClassPeopleOptions();
    fetchClassStyleOptions();
    fetchCategoryOptions();
  },[]);

  const handleCategoryCahnge = async(e) => {
    const selectedCategory = parseInt(e.target.value);
    console.log('選択したカテゴリー:', selectedCategory);
    setCategories(selectedCategory);
    const selectedLabel = e.target.options[e.target.selectedIndex].label 
    setCategoryName(selectedLabel);
    console.log('EEEE:',selectedLabel)
    

  
      setIsSubjectEnabled(true);
      await fetchSubjectOptions(selectedCategory);
    };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    console.log('FFFFFFF:',e.target.options);
    const selectedsubjectlabel = e.target.options[e.target.selectedIndex].label
    console.log('selectedsubject:',selectedsubjectlabel);
    setSubjectName(selectedsubjectlabel);
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name); // ファイル名を設定
    setButtonLabel(e.target.files[0].name);
  };

  const fetchSubjectOptions = async (selectedCategory) => {
    const ListSubjectsByCategory = `
    query listQuetions ($minnum: Int!, $maxnum: Int!) {
      listQuetions(filter: {PageNo: {eq: 8},SortNumber:{between:[$minnum,$maxnum]}}) {
        items {
          PageNo
          QuetionItem
          SortNumber
          id
        }
      }
    }
    `
    let minnum, maxnum;
    if (selectedCategory === 1){
      minnum = 1;
      maxnum = 6;
    } else if (selectedCategory === 2){
      minnum = 7;
      maxnum = 11;
    } else if (selectedCategory === 3){
      minnum = 12;
      maxnum = 16;
    } else {
      minnum = 1;
      maxnum = 16;
    }




    try {
      const response = await API.graphql({
        query:ListSubjectsByCategory,
        variables:{minnum,maxnum}
      
      })
      //console.log('responseの内容:',response);

      const subjects = response.data.listQuetions.items;
      console.log('minnum:',minnum);
      console.log('category:',selectedCategory);
      subjects.sort((a, b) => a.SortNumber - b.SortNumber);
      console.log('subject',subjects);
      const updatedOptions = subjects.map((subject) => ({
        value: subject.SortNumber,
        label: subject.QuetionItem
      }));
      setSubjectOptions(updatedOptions);
      console.log('科目の内容:', updatedOptions);
    } catch (error) {
    console.error('科目の取得エラー:',error);
    }
  }

  const handleFileUpload = async () => {
    try {
      setUploading(true);

      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
      const ownername = user.attributes.name;

      const path = `uploads/${userId}/${selectedFile.name}`;
      await Storage.put(path, selectedFile);

      console.log('ファイルがS3にアップロードされました。', path);
      setUploadedFilePath(path);
      setContentOwnerName(ownername);
    } catch (error) {
      console.error('ファイルアップロードエラー:', error);
    } finally {
      setUploading(false);
    }
  };

  const writeToDynamoDB = async () => {
    try {
      const newContent = {
        Title: title,
        Abstract: abstract,
        Detail: detail,
        Category: categories,
        Subject: subject,
        ClassLevel: classLevel,
        ClassPeople: classPeople,
        ClassStyle: classStyle,
        FileSrc: uploadedFilePath,
        CategoryName: categoryname,
        SubjectName: subjectname,
        UserID: contentownername,
        Meate: meate,
   		Nerai:nerai,
  	    Matome:matome
      }

      // GraphQL APIを使用してDynamoDBにデータを書き込む
      const apiResponse = await API.graphql({
        query: `
          mutation createContents($input: CreateContentsInput!) {
            createContents(input: $input) {
              id
              Title
              Abstract
              Detail
              Category
              Subject
              ClassLevel
              ClassPeople
              ClassStyle
              FileSrc
              CategoryName
              SubjectName
              UserID
              Meate
   			  Nerai
  		   	  Matome
            }
          }
        `,
        variables: { input: newContent }, 
      });

      console.log('DynamoDBにデータが書き込まれました。', apiResponse);
    } catch (error) {
      console.error('データ書き込みエラー:', error);
    }
  };

  const handleSelectFileClick = () => {
    // ファイル選択用のinput要素をクリックする
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await handleFileUpload(); // ファイルのアップロードを実行し待機
      if (uploadedFilePath) {
        await writeToDynamoDB(); // DynamoDBへの書き込みを実行し待機
  
        // ファイルのアップロードとDynamoDBへの書き込みが完了した後に実行したいコードをここに追加できます
  
      } else {
        console.error('アップロードされたファイルまたはテキストがありません。');
      }
    } catch (error) {
      console.error('処理中にエラーが発生しました:', error);
    }
  };

  return (
    <div>
    <div className="Headerfixed">
      <StaticHeader />
    </div>
    
     <div className="contentmiddle">
     
     <div className="contentmiddle80">
    
      <h1  class="headingContensUP">ファイルをアップロード</h1>
      <div
        style={{
          border: '2px dashed #ccc',
          borderRadius: '4px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        onClick={handleSelectFileClick}
      >
        {selectedFileName ||'クリックしてファイルを選択してください。'}
        <input
          type="file"
          style={{ display: 'none' }}
          id="fileInput"
          onChange={handleFileChange}
          
        />
        </div>
        
         <div style={{ marginBottom: '2%' }}></div>
      
        <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="title" class="headingContensUP">タイトル:</label>
        <Input
          id="title"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ backgroundColor: 'white' }}
        />
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>

      <div>
        <label htmlFor="abstract" class="headingContensUP">概要:</label>
        <TextField
          id="abstract"
          name="abstract"
          value={abstract}
          onChange={(e) => setAbstract(e.target.value)}
          style={{ backgroundColor: 'white' }}
        />
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>
      
      <div>
        <label htmlFor="meate" class="headingContensUP">めあて:</label>
        <TextField
          id="meate"
          name="meate"
          value={meate}
          onChange={(e) => setMeate(e.target.value)}
          style={{ backgroundColor: 'white' }}
        />
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>
      
      <div>
      <label htmlFor="nerai" class="headingContensUP">ねらい:</label>
        <TextField
          id="nerai"
          name="nerai"
          value={nerai}
          onChange={(e) => setNerai(e.target.value)}
          style={{ backgroundColor: 'white' }}
        />
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>
      
      <div>
      <label htmlFor="matome" class="headingContensUP">まとめ:</label>
        <TextField
          id="matome"
          name="matome"
          value={matome}
          onChange={(e) => setMatome(e.target.value)}
          style={{ backgroundColor: 'white' }}
        />
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>

      <div>
        <label htmlFor="detail" class="headingContensUP">詳細:</label>
        <TextField
          id="detail"
          name="detail"
          value={detail}
          onChange={(e) => setDetail(e.target.value)}
          style={{ backgroundColor: 'white' }}
        />
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>

      <div>
        <label htmlFor="category" class="headingContensUP">カテゴリ:</label>
        <SelectField
          id="category"
          name="category"
          type="number"
          value={categories}
          onChange= {handleCategoryCahnge}>
          {categoryOptions.map((option) => (<option key={option.value} value={option.value} label={option.label}>
            </option>
          )
          )}
        </SelectField>
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>

      <div>
        <label htmlFor="subject" class="headingContensUP">小学生の場合は学年、それ以外は科目 :</label>
        <SelectField
          id="subject"
          name="subject"
          type='number'
          value={subject}
          onChange={handleSubjectChange}
          >
          {subjectOptions.map((option) => (<option key={option.value} value={option.value} label={option.label}>
            </option>
          )
          )}
          </SelectField>
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>

      <div>
        <label htmlFor="classLevel" class="headingContensUP">クラスレベル:</label>
        <SelectField
          id="classLevel"
          name="classLevel"
          type="number"
          value={classLevel}
          onChange={(e) => setClassLevel(e.target.value)}>
          {classlevelOptions.map((option) => (<option key={option.value} value={option.value} label={`${option.value}. ${option.label}`}>
            </option>
            )
          )}
        </SelectField>
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>

      <div>
        <label htmlFor="classPeople" class="headingContensUP">クラス人数:</label>
        <SelectField
          id="classPeople"
          name="classPeople"
          type="number"
          value={classPeople}
          onChange={(e) => setClassPeople(e.target.value)}>
          {classpeopleOptions.map((option) => (<option key={option.value} value={option.value} label={`${option.value}. ${option.label}`}>
            </option>
            )
          )}
          </SelectField>
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>
      
      <div>
        <label htmlFor="classStyle" class="headingContensUP">クラススタイル:</label>
        <SelectField
          id="classStyle"
          name="classStyle"
          type="number"
          value={classStyle}
          onChange={(e) => setClassStyle(e.target.value)}>
          {classstyleOptions.map((option) => (<option key={option.value} value={option.value} label={`${option.value}. ${option.label}`}>
            </option>
            )
          )}
          </SelectField>
      </div>
      
      <div style={{ marginBottom: '2%' }}></div>


      <Button type="submit"  style={{ backgroundColor: "#D17091", color: "white" ,width: "20%"}}>送 信</Button>
    </form>

      {uploading && <p>ファイルをアップロード中...</p>}
      {uploadedFilePath && (
        <div>
          <p>アップロードしたファイルのパス: {uploadedFilePath}</p>
        </div>
      )}
      
      </div>
      </div>
      
      
    <div className="Footerfixed">
      <Footer />
    </div>
    
    </div>
  );
}

export default FileUploadComponent;
