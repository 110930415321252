/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Qanswer } from "../models";
import {
  getOverrideProps,
  useDataStoreCreateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { schema } from "../models/schema";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function QuestionItem2(props) {
  const { quetions, overrides, ...rest } = props;
  const [buttonBackgroundColor, setButtonBackgroundColor] =
    useStateMutationAction("rgba(220,222,224,1)");
  const questionItemTwoOnClick = useDataStoreCreateAction({
    fields: {
      UserID: "10",
      AnsID: "10",
      A01: "10",
      A02: "10",
      A03: "10",
      A04: "10",
      A05: "10",
      A06: "10",
    },
    model: Qanswer,
    schema: schema,
  });
  const buttonOnClick = () => {
    setButtonBackgroundColor("#D17091");
  };
  return (
    <Flex
      gap="10px"
      direction="column"
      width="664px"
      height="146px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="27px 45px 27px 45px"
      backgroundColor="rgba(255,255,255,1)"
      onClick={() => {
        questionItemTwoOnClick();
      }}
      {...getOverrideProps(overrides, "QuestionItem2")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="442px"
        height="97px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="30px"
        padding="7px 15px 7px 15px"
        backgroundColor={buttonBackgroundColor}
        onClick={() => {
          buttonOnClick();
        }}
        {...getOverrideProps(overrides, "Button")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={quetions?.QuetionItem}
          {...getOverrideProps(overrides, "label")}
        ></Text>
      </Flex>
    </Flex>
  );
}
