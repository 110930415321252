/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubjectUnit = /* GraphQL */ `
  mutation CreateSubjectUnit(
    $input: CreateSubjectUnitInput!
    $condition: ModelSubjectUnitConditionInput
  ) {
    createSubjectUnit(input: $input, condition: $condition) {
      id
      PrimarySchoolYear
      PrimarySchoolSubject
      JuniorHighSchoolSubject
      JuniorHighSchoolYear
      HighSchoolSubjectCategory
      HighSchoolSubject
      publisher
      Unit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSubjectUnit = /* GraphQL */ `
  mutation UpdateSubjectUnit(
    $input: UpdateSubjectUnitInput!
    $condition: ModelSubjectUnitConditionInput
  ) {
    updateSubjectUnit(input: $input, condition: $condition) {
      id
      PrimarySchoolYear
      PrimarySchoolSubject
      JuniorHighSchoolSubject
      JuniorHighSchoolYear
      HighSchoolSubjectCategory
      HighSchoolSubject
      publisher
      Unit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSubjectUnit = /* GraphQL */ `
  mutation DeleteSubjectUnit(
    $input: DeleteSubjectUnitInput!
    $condition: ModelSubjectUnitConditionInput
  ) {
    deleteSubjectUnit(input: $input, condition: $condition) {
      id
      PrimarySchoolYear
      PrimarySchoolSubject
      JuniorHighSchoolSubject
      JuniorHighSchoolYear
      HighSchoolSubjectCategory
      HighSchoolSubject
      publisher
      Unit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQanswer = /* GraphQL */ `
  mutation CreateQanswer(
    $input: CreateQanswerInput!
    $condition: ModelQanswerConditionInput
  ) {
    createQanswer(input: $input, condition: $condition) {
      id
      UserID
      AnsID
      A01
      A02
      A03
      A04
      A05
      A06
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQanswer = /* GraphQL */ `
  mutation UpdateQanswer(
    $input: UpdateQanswerInput!
    $condition: ModelQanswerConditionInput
  ) {
    updateQanswer(input: $input, condition: $condition) {
      id
      UserID
      AnsID
      A01
      A02
      A03
      A04
      A05
      A06
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQanswer = /* GraphQL */ `
  mutation DeleteQanswer(
    $input: DeleteQanswerInput!
    $condition: ModelQanswerConditionInput
  ) {
    deleteQanswer(input: $input, condition: $condition) {
      id
      UserID
      AnsID
      A01
      A02
      A03
      A04
      A05
      A06
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuestionBase = /* GraphQL */ `
  mutation CreateQuestionBase(
    $input: CreateQuestionBaseInput!
    $condition: ModelQuestionBaseConditionInput
  ) {
    createQuestionBase(input: $input, condition: $condition) {
      id
      QBase
      QNo
      MatchWeight
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuestionBase = /* GraphQL */ `
  mutation UpdateQuestionBase(
    $input: UpdateQuestionBaseInput!
    $condition: ModelQuestionBaseConditionInput
  ) {
    updateQuestionBase(input: $input, condition: $condition) {
      id
      QBase
      QNo
      MatchWeight
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuestionBase = /* GraphQL */ `
  mutation DeleteQuestionBase(
    $input: DeleteQuestionBaseInput!
    $condition: ModelQuestionBaseConditionInput
  ) {
    deleteQuestionBase(input: $input, condition: $condition) {
      id
      QBase
      QNo
      MatchWeight
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuetions = /* GraphQL */ `
  mutation CreateQuetions(
    $input: CreateQuetionsInput!
    $condition: ModelQuetionsConditionInput
  ) {
    createQuetions(input: $input, condition: $condition) {
      id
      PageNo
      SortNumber
      QuetionItem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuetions = /* GraphQL */ `
  mutation UpdateQuetions(
    $input: UpdateQuetionsInput!
    $condition: ModelQuetionsConditionInput
  ) {
    updateQuetions(input: $input, condition: $condition) {
      id
      PageNo
      SortNumber
      QuetionItem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuetions = /* GraphQL */ `
  mutation DeleteQuetions(
    $input: DeleteQuetionsInput!
    $condition: ModelQuetionsConditionInput
  ) {
    deleteQuetions(input: $input, condition: $condition) {
      id
      PageNo
      SortNumber
      QuetionItem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      Comment
      UserID
      ContentsID
      ContentsComment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      Comment
      UserID
      ContentsID
      ContentsComment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      Comment
      UserID
      ContentsID
      ContentsComment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContents = /* GraphQL */ `
  mutation CreateContents(
    $input: CreateContentsInput!
    $condition: ModelContentsConditionInput
  ) {
    createContents(input: $input, condition: $condition) {
      id
      Title
      Abstract
      Detail
      Subject
      Category
      ContentsComment {
        nextToken
        startedAt
        __typename
      }
      Unit
      SubjectUnitID
      ClassLevel
      ClassPeople
      ClassStyle
      FileSrc
      UserID
      Good
      Meate
      Nerai
      Matome
      CategoryName
      SubjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContents = /* GraphQL */ `
  mutation UpdateContents(
    $input: UpdateContentsInput!
    $condition: ModelContentsConditionInput
  ) {
    updateContents(input: $input, condition: $condition) {
      id
      Title
      Abstract
      Detail
      Subject
      Category
      ContentsComment {
        nextToken
        startedAt
        __typename
      }
      Unit
      SubjectUnitID
      ClassLevel
      ClassPeople
      ClassStyle
      FileSrc
      UserID
      Good
      Meate
      Nerai
      Matome
      CategoryName
      SubjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContents = /* GraphQL */ `
  mutation DeleteContents(
    $input: DeleteContentsInput!
    $condition: ModelContentsConditionInput
  ) {
    deleteContents(input: $input, condition: $condition) {
      id
      Title
      Abstract
      Detail
      Subject
      Category
      ContentsComment {
        nextToken
        startedAt
        __typename
      }
      Unit
      SubjectUnitID
      ClassLevel
      ClassPeople
      ClassStyle
      FileSrc
      UserID
      Good
      Meate
      Nerai
      Matome
      CategoryName
      SubjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      UserIconFileName
      SchoolType
      PrimarySchoolYear
      JuniorHighSchoolSubject
      HighSchoolSubjectCategory
      CognitoID
      IdentityID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      UserIconFileName
      SchoolType
      PrimarySchoolYear
      JuniorHighSchoolSubject
      HighSchoolSubjectCategory
      CognitoID
      IdentityID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      UserIconFileName
      SchoolType
      PrimarySchoolYear
      JuniorHighSchoolSubject
      HighSchoolSubjectCategory
      CognitoID
      IdentityID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
