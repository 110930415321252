/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, {useEffect, useState} from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
import { Storage } from 'aws-amplify';
export default function DetailAreaNew2(props) {
  const { contents, overrides, ...rest } = props;
  
  const [imagePath,setImagePath]=useState("");
  
  console.log('imagePath:',imagePath)
  
  useEffect(() => {
      async function fetchImage() {
        try {
            const url = await Storage.get(contents.FileSrc);
            setImagePath(url);
            console.log('S3のパス:',url)
        } catch (error) {
            console.error("画像の取得エラー",error);
      }
    }
    fetchImage();
    },[contents]
    )
  
  
  return (
    <Flex
      gap="10px"
      direction="row"
      width="664px"
      height="656px"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "DetailAreaNew2")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="664px"
        height="642px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="20px 80px 83px 80px"
        {...getOverrideProps(overrides, "Frame 36")}
      >
        <Text
          fontFamily="Inter"
          fontSize="30px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="25px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="511px"
          height="30px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={contents?.Title}
          {...getOverrideProps(
            overrides,
            "\u6570\u5B66 I\uFF1A\u30B0\u30E9\u30D5\u3068\uFF12\u6B21\u65B9\u7A0B\u5F0F"
          )}
        ></Text>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Tags")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="32px"
            padding="6px 8px 6px 8px"
            backgroundColor="rgba(205,112,143,1)"
            {...getOverrideProps(overrides, "Badge4003330")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="600"
              color="rgba(255,255,255,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={contents?.SubjectName}
              {...getOverrideProps(overrides, "label4003331")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="32px"
            padding="6px 8px 6px 8px"
            backgroundColor="rgba(209,112,145,1)"
            {...getOverrideProps(overrides, "Badge4003332")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="600"
              color="rgba(255,255,255,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={contents?.CategoryName}
              {...getOverrideProps(overrides, "label4003333")}
            ></Text>
          </Flex>
        </Flex>
        <Image
          width="519px"
          height="346px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={imagePath}
          {...getOverrideProps(overrides, "image")}
        ></Image>
        <Flex
          gap="0"
          direction="row"
          width="519px"
          height="139px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,0)"
          padding="7px 0px 7px 15px"
          backgroundColor="rgba(239,240,240,1)"
          {...getOverrideProps(overrides, "CommentArea")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="30px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="486px"
            height="72px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={contents?.Abstract}
            {...getOverrideProps(overrides, "Detailtext")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
