/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Nerai(props) {
  const { contents, overrides, ...rest } = props;
  return (
    <Flex
      gap="5px"
      direction="column"
      width="664px"
      height="139px"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="8px 45px 8px 80px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Nerai")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="15px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="18.15340805053711px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ねらい"
        {...getOverrideProps(overrides, "UserName")}
      ></Text>
      <Flex
        gap="0"
        direction="row"
        width="519px"
        height="91px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0)"
        padding="7px 0px 7px 15px"
        backgroundColor="rgba(239,240,240,1)"
        {...getOverrideProps(overrides, "CommentArea")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="486px"
          height="72px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={contents?.Nerai}
          {...getOverrideProps(overrides, "label")}
        ></Text>
      </Flex>
    </Flex>
  );
}
