import '../App.css';
import { StaticHeader ,Footer} from '../ui-components';

import React, { useState, useEffect } from "react";
import {Amplify,  API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import awsconfig from "../aws-exports";

import { useSearchParams } from "react-router-dom";

import moment from 'moment-timezone';


Amplify.configure(awsconfig);



 //Stateを使ってコメント投稿を管理
function Comments(props) {

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  

// SerchParamsでURLにくっつけている変数の取得
  const [searchParams] = useSearchParams();
  console.log("searchParams", searchParams)
  const articleId = searchParams.get('article_id');
  console.log("articleId", articleId)


// コメントが紐づく記事ID、一旦ハードコーディング。今後呼び出した元ページに紐づくよう変更予定
//  const articleId = "1de59468-9d8b-4f67-9fde-9b0cda61a1db";

  useEffect(() => {
    fetchComments();
  }, []);


// コメントリストの一覧を表示
  const fetchComments = async () => {
    const commentsData = await API.graphql(
      graphqlOperation(queries.listComments, {
        filter: { ContentsComment: { eq: articleId } },
//        limit: 30, // 1ページあたりのアイテム数
//      　nextToken: lastItem // 次のページの最初のアイテム
      })
    );
//    setComments(commentsData.data.listComments.items);

// コメントを降順で表示
  const sortedComments = commentsData.data.listComments.items.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
    setComments(sortedComments);
//　  setLastItem(commentsData.data.listComments.nextToken);
  };


// コメントのページ分割
//const [currentPage, setCurrentPage] = useState(1);
//const [lastItem, setLastItem] = useState(null);


// コメントの追加
  const addComment = async () => {
    if (newComment === "") return;
    await API.graphql(
      graphqlOperation(mutations.createComment, {
        input: { Comment: newComment, ContentsComment: articleId },
      })
    );
    setNewComment("");
    fetchComments();
  };


 const styleComment ={
    backgroundColor:"#f8f8ff",
    width:"500px",
    fontSize:"12pt",
    marginLeft:"auto",
    marginRight:"auto",
    padding:"0px",
    textAlign:"left",
   
 };
 
  const styleCommentTime ={
    width:"500px",
    fontSize:"8pt",
    marginLeft:"auto",
    marginRight:"auto",
    textAlign:"left",
   
 };
 
  const styleCommentForm ={
    backgroundColor:"white",
    width:"500px",
    fontSize:"14pt",
    flex:"0.3",
    marginLeft:"auto",
    marginRight:"auto",
    padding:"10px",
   
 };
 
 
   const styleButton ={
    backgroundColor:"#D17091",
    width:"500px",
    fontSize:"14pt",
    marginLeft:"0px",
    marginRight:"auto",
    padding:"10px",
    color:"white",
    borderColor:"#D17091",
    fontWeight:"700",
   
 };
 
    const divAll ={
      marginRight:"auto",
 };
 

 

  return (
    <div style={divAll}>
 
      <br />

        {comments.map((comment) => (
        <div key={comment.id}>
          <div style={styleCommentTime}>
            {moment.tz(comment.createdAt, 'Asia/Tokyo').fromNow()}
          </div>
          <div style={styleComment}>{comment.Comment}</div>
        </div>
      ))}


      <div style={{marginLeft:"auto",marginRight:"auto"}}>
      
        <textarea style={styleCommentForm}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />

        <br />

        <button onClick={addComment} style={styleButton}>コメント投稿</button>
      
      </div>
      

  
    </div>
  );
}


  //  <button onClick={() => setCurrentPage(currentPage + 1)}>次へ</button>
  //    <button onClick={() => setCurrentPage(currentPage - 1)}>前へ</button>

export default Comments;