import { SelectField } from '@aws-amplify/ui-react';
import React, { useState } from 'react';

function Select_kyouka() {
    
  const [subject, setSubject] = useState('');
  const [topic, setTopic] = useState('');

  const subjects = ["数学", "国語", "理科"];

  const topics = {
    "数学": ["数Ⅰ", "数Ⅱ", "数Ⅲ"],
    "国語": ["現代文", "古文"],
    "理科": ["物理", "化学"]
  };
    
  return (

     <div>
      <select value={subject} onChange={e => setSubject(e.target.value)}>
        <option value="">-- 科目を選択 --</option>
        {subjects.map(s => (
          <option key={s} value={s}>{s}</option>
        ))}
      </select>
      <br />

      <select value={topic} onChange={e => setTopic(e.target.value)}>
        <option value="">-- トピックを選択 --</option>
        {subject && topics[subject].map(t => (
          <option key={t} value={t}>{t}</option>
        ))}
      </select>
    </div>
  
  
);
}
export default Select_kyouka;