import '../App.css';
// === Add the following ===
import { Amplify } from 'aws-amplify';
import { StaticHeader , SelectStart,Footer} from '../ui-components';
//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);
// ======
function App() {
  return (
    <div className="ContentsList">
      <StaticHeader/>
      <SelectStart/>
      <Footer/>
    </div>
  );
}

export default App;