/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function QuestionItemFoot(props) {
  const { overrides, ...rest } = props;
  const frameThreeOneOnClick = useNavigateAction({
    type: "url",
    url: "/CheckQuestion",
  });
  const frameThreeZeroOnClick = useNavigateAction({
    type: "url",
    url: "/CheckQuestion2",
  });
  return (
    <Flex
      gap="118px"
      direction="row"
      width="664px"
      height="100%"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="28px 55px 28px 55px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "QuestionItemFoot")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="218px"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        overflow="hidden"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(209,112,145,1)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="20px"
        padding="21px 104px 21px 104px"
        backgroundColor="rgba(0,0,0,0)"
        onClick={() => {
          frameThreeOneOnClick();
        }}
        {...getOverrideProps(overrides, "Frame 31")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(174,179,183,1)"
          lineHeight="22px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="＜　　戻る"
          {...getOverrideProps(overrides, "text39455125")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="218px"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        overflow="hidden"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(209,112,145,1)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="20px"
        padding="21px 104px 21px 104px"
        backgroundColor="rgba(205,112,143,1)"
        onClick={() => {
          frameThreeZeroOnClick();
        }}
        {...getOverrideProps(overrides, "Frame 30")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="22px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="次へ　　＞"
          {...getOverrideProps(overrides, "text39455127")}
        ></Text>
      </Flex>
    </Flex>
  );
}
