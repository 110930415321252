// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { SubjectUnit, Qanswer, QuestionBase, Quetions, Comment, Contents, UserProfile } = initSchema(schema);

export {
  SubjectUnit,
  Qanswer,
  QuestionBase,
  Quetions,
  Comment,
  Contents,
  UserProfile
};