import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import Home from './pages/Home';
import MyPage from './pages/MyPage';
import ContentsList from './pages/ContentsList';
import ContentsUp from './pages/ContentsUp';
import CheckStart from './pages/CheckStart';
import CheckQuestion from './pages/CheckQuestion';
import CheckQuestion2 from './pages/CheckQuestion2';
import CheckQuestion3 from './pages/CheckQuestion3';
import CheckQuestion4 from './pages/CheckQuestion4';
import CheckQuestion5 from './pages/CheckQuestion5';
import CheckQuestion6 from './pages/CheckQuestion6';
import ContentsPage from './pages/ContentsPage';
import DetailAreaNew from './pages/DetailAreaNew';
import Comments from './pages/Comments';
import select_kyouka from './pages/select_kyouka';
import CommunityList from './pages/CommunityList';


import "./App.css";

const App = () => {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/MyPage' element={<MyPage />} />
        <Route path='/ContentsList' element={<ContentsList/>} />
        <Route path='/ContentsUp' element={<ContentsUp/>} />
        <Route path='/CheckStart' element={<CheckStart/>} />
        <Route path='/CheckQuestion' element={<CheckQuestion/>} />
        <Route path='/CheckQuestion2' element={<CheckQuestion2/>} />
        <Route path='/CheckQuestion3' element={<CheckQuestion3/>} />
        <Route path='/CheckQuestion4' element={<CheckQuestion4/>} />
        <Route path='/CheckQuestion5' element={<CheckQuestion5/>} />
        <Route path='/CheckQuestion6' element={<CheckQuestion6/>} />
        <Route path='/ContentsPage' element={<ContentsPage/>} />
        <Route path='/Comments' element={<Comments/>} />
        <Route path='/DetailAreaNew' element={<DetailAreaNew/>} />
        <Route path='/select_kyouka' element={<select_kyouka/>} />
        <Route path='/CommunityList' element={<CommunityList/>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
};

export default withAuthenticator(App);