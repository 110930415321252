/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSubjectUnit = /* GraphQL */ `
  query GetSubjectUnit($id: ID!) {
    getSubjectUnit(id: $id) {
      id
      PrimarySchoolYear
      PrimarySchoolSubject
      JuniorHighSchoolSubject
      JuniorHighSchoolYear
      HighSchoolSubjectCategory
      HighSchoolSubject
      publisher
      Unit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSubjectUnits = /* GraphQL */ `
  query ListSubjectUnits(
    $filter: ModelSubjectUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubjectUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PrimarySchoolYear
        PrimarySchoolSubject
        JuniorHighSchoolSubject
        JuniorHighSchoolYear
        HighSchoolSubjectCategory
        HighSchoolSubject
        publisher
        Unit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSubjectUnits = /* GraphQL */ `
  query SyncSubjectUnits(
    $filter: ModelSubjectUnitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubjectUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        PrimarySchoolYear
        PrimarySchoolSubject
        JuniorHighSchoolSubject
        JuniorHighSchoolYear
        HighSchoolSubjectCategory
        HighSchoolSubject
        publisher
        Unit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQanswer = /* GraphQL */ `
  query GetQanswer($id: ID!) {
    getQanswer(id: $id) {
      id
      UserID
      AnsID
      A01
      A02
      A03
      A04
      A05
      A06
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQanswers = /* GraphQL */ `
  query ListQanswers(
    $filter: ModelQanswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQanswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserID
        AnsID
        A01
        A02
        A03
        A04
        A05
        A06
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQanswers = /* GraphQL */ `
  query SyncQanswers(
    $filter: ModelQanswerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQanswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        UserID
        AnsID
        A01
        A02
        A03
        A04
        A05
        A06
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuestionBase = /* GraphQL */ `
  query GetQuestionBase($id: ID!) {
    getQuestionBase(id: $id) {
      id
      QBase
      QNo
      MatchWeight
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQuestionBases = /* GraphQL */ `
  query ListQuestionBases(
    $filter: ModelQuestionBaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        QBase
        QNo
        MatchWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuestionBases = /* GraphQL */ `
  query SyncQuestionBases(
    $filter: ModelQuestionBaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestionBases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        QBase
        QNo
        MatchWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuetions = /* GraphQL */ `
  query GetQuetions($id: ID!) {
    getQuetions(id: $id) {
      id
      PageNo
      SortNumber
      QuetionItem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQuetions = /* GraphQL */ `
  query ListQuetions(
    $filter: ModelQuetionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuetions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PageNo
        SortNumber
        QuetionItem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuetions = /* GraphQL */ `
  query SyncQuetions(
    $filter: ModelQuetionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuetions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        PageNo
        SortNumber
        QuetionItem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      Comment
      UserID
      ContentsID
      ContentsComment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Comment
        UserID
        ContentsID
        ContentsComment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Comment
        UserID
        ContentsID
        ContentsComment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByContentsComment = /* GraphQL */ `
  query CommentsByContentsComment(
    $ContentsComment: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByContentsComment(
      ContentsComment: $ContentsComment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Comment
        UserID
        ContentsID
        ContentsComment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContents = /* GraphQL */ `
  query GetContents($id: ID!) {
    getContents(id: $id) {
      id
      Title
      Abstract
      Detail
      Subject
      Category
      ContentsComment {
        nextToken
        startedAt
        __typename
      }
      Unit
      SubjectUnitID
      ClassLevel
      ClassPeople
      ClassStyle
      FileSrc
      UserID
      Good
      Meate
      Nerai
      Matome
      CategoryName
      SubjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Title
        Abstract
        Detail
        Subject
        Category
        Unit
        SubjectUnitID
        ClassLevel
        ClassPeople
        ClassStyle
        FileSrc
        UserID
        Good
        Meate
        Nerai
        Matome
        CategoryName
        SubjectName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContents = /* GraphQL */ `
  query SyncContents(
    $filter: ModelContentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Title
        Abstract
        Detail
        Subject
        Category
        Unit
        SubjectUnitID
        ClassLevel
        ClassPeople
        ClassStyle
        FileSrc
        UserID
        Good
        Meate
        Nerai
        Matome
        CategoryName
        SubjectName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      UserIconFileName
      SchoolType
      PrimarySchoolYear
      JuniorHighSchoolSubject
      HighSchoolSubjectCategory
      CognitoID
      IdentityID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserIconFileName
        SchoolType
        PrimarySchoolYear
        JuniorHighSchoolSubject
        HighSchoolSubjectCategory
        CognitoID
        IdentityID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserProfiles = /* GraphQL */ `
  query SyncUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        UserIconFileName
        SchoolType
        PrimarySchoolYear
        JuniorHighSchoolSubject
        HighSchoolSubjectCategory
        CognitoID
        IdentityID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
