import '../App.css';
import { Amplify,Auth  } from 'aws-amplify';
import { StaticHeader, QuestionItemHead, QuestionItemCollectionFirst,QuestionItemCollectionSecond,QuestionItemCollectionThird,QuestionItemCollectionFourth,QuestionItemCollectionFifth,QuestionItemCollectionSixth, QuestionItemFoot, Footer } from '../ui-components';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import React, { createContext, useState, useEffect,  } from 'react';
import { useDataStoreCreateAction, useNavigateAction } from "@aws-amplify/ui-react/internal";
import { Qanswer } from "../models";
import { schema } from "../models/schema";

export const MyContext = createContext();

Amplify.configure(awsExports);

function CheckQuestion() {
  
  const [userAttributes, setUserAttributes] = useState(null);
  const navigateAction = useNavigateAction({ type: "url", url: "/ContentsList" });

useEffect(() => {
  async function fetchUserAttributes() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // ユーザーの属性を取得
      const attributes = user.attributes;
      setUserAttributes({
        name: attributes.name,
        email: attributes.email,
        birthdate: attributes.birthdate,
        userId: attributes.sub, // ユーザーIDはsub属性に格納されています
      });
    } catch (error) {
      console.error('ユーザー情報の取得に失敗しました: ', error);
      // エラーハンドリングを追加するか、適切にエラーを処理してください。
    }
  }

  fetchUserAttributes();
}, []);


  const [PageNo, setPageNo] = useState(0);
  const [showQuestion, setShowQuestion] = useState(true);
  const [showQuestion2, setShowQuestion2] = useState(false);
  const [showQuestion3, setShowQuestion3] = useState(false);
  const [showQuestion4, setShowQuestion4] = useState(false);
  const [showQuestion5, setShowQuestion5] = useState(false);
  const [showQuestion6, setShowQuestion6] = useState(false);
  const [x, setX] = useState('');
  const [AnsNo1, setAnsNo1] = useState(0);
  const [AnsNo2, setAnsNo2] = useState(0);
  const [AnsNo3, setAnsNo3] = useState(0);
  const [AnsNo4, setAnsNo4] = useState(0);  
  const [AnsNo5, setAnsNo5] = useState(0);
  const [AnsNo6, setAnsNo6] = useState(0);
  
  
  //ダイナモDBへのアップ　QuetionItem2のアップをコメントアウトすること
  const dataStoreCreateAction = useDataStoreCreateAction({
     fields: {
      //PageNo: "3",
    //AnsNo: "3",
      UserID: userAttributes?.userId || "defaultUserId", // nullチェックを追加
      AnsID: "1007",
      A01: AnsNo1,
      A02: AnsNo2,
      A03: AnsNo3,
      A04: AnsNo4,
      A05: AnsNo5,
      A06: AnsNo6,
    },
    model: Qanswer,
    schema: schema,
  });

  const handleFrame30Click = () => {
    setShowQuestion(false);
    setShowQuestion2(true);
    setShowQuestion3(false);
    setShowQuestion4(false);
    setShowQuestion5(false);
    setShowQuestion6(false);
    setPageNo(1);
    setX('x value here');
  };

  const handleFrame30Click2 = () => {
    setShowQuestion(false);
    setShowQuestion2(false);
    setShowQuestion3(true);
    setShowQuestion4(false);
    setShowQuestion5(false);
    setShowQuestion6(false);
    setPageNo(2);
    setX('x value here');
  };

  const handleFrame30Click3 = () => {
    setShowQuestion(false);
    setShowQuestion2(false);
    setShowQuestion3(false);
    setShowQuestion4(true);
    setShowQuestion5(false);
    setShowQuestion6(false);
    setPageNo(3);
    setX('x value here');

  };

  const handleFrame30Click4 = () => {
    setShowQuestion2(false);
    setShowQuestion3(false);
    setShowQuestion4(false);
    setShowQuestion5(true);
    setShowQuestion6(false);
    setPageNo(4);
    setX('x value here');

  };

  const handleFrame30Click5 = () => {
    setShowQuestion2(false);
    setShowQuestion3(false);
    setShowQuestion4(false);
    setShowQuestion5(false);
    setShowQuestion6(true);
    setPageNo(5);

  };

  const handleFinalClick = () => {
    setShowQuestion6(false);
    setPageNo(6);
    navigateAction();
    dataStoreCreateAction();
  };
  
  return (
    <div className="ContentsList">
       <div className="Headerfixed">
          <StaticHeader />
      </div>
      
      <div className="contentmiddle">
      
      <MyContext.Provider value={PageNo}>
        {showQuestion && (
        <>
          <QuestionItemHead
        overrides={{
          "text39455121": {
            children: "Q1 どのレベルの内容を求めていますか",
            fontSize:"28px"
          }
        }}/>
          <QuestionItemCollectionFirst
            overrideItems={({ item, index}) => ({
              style: { cursor: "pointer"},
              onClick: () => {
                setPageNo(item.SortNumber);
                setX(item.SortNumber);
                setAnsNo1(item.SortNumber);
              }
            })}
          />
        </>
        )}
        {showQuestion2 && !showQuestion3 && (
        <>
          <QuestionItemHead
        overrides={{
          "text39455121": {
            children: "Q2 次の内どの生徒にアプローチしたいですか",
            fontSize:"28px"
          }
        }}/>
          <QuestionItemCollectionSecond
             overrideItems={({ item, index}) => ({
              style: { cursor: "pointer"},
              onClick: () => {
                setPageNo(item.SortNumber);
                setX(item.SortNumber);
                setAnsNo2(item.SortNumber);
              }
            })}/>
        </>
        )}
        {showQuestion3 && !showQuestion4 && (
        <>
          <QuestionItemHead
         overrides={{
          "text39455121": {
            children:"Q3 1つの授業で対象とする生徒数は何人ですか",
            fontSize:"28px"
          }
          }}/>
          <QuestionItemCollectionThird 
             overrideItems={({ item, index}) => ({
              style: { cursor: "pointer"},
              onClick: () => {
                setPageNo(item.SortNumber);
                setX(item.SortNumber);
                setAnsNo3(item.SortNumber);
              }
            })}/>
         </>
        )}
        {showQuestion4 && !showQuestion5 && (
        <>
          <QuestionItemHead
         overrides={{
          "text39455121": {
            children:"Q4 あなたが授業でよく使うツールを教えてください",
            fontSize:"28px"
          }
          }}/>
          <QuestionItemCollectionFourth
              overrideItems={({ item, index}) => ({
              style: { cursor: "pointer"},
              onClick: () => {
                setPageNo(item.SortNumber);
                setX(item.SortNumber);
                setAnsNo4(item.SortNumber);
              }
            })}/>
       </>
        )}
        {showQuestion5 && !showQuestion6 && (
        <>
          <QuestionItemHead
         overrides={{
          "text39455121": {
            children:"Q5 あなたがよく行う授業スタイルを教えてください",
            fontSize:"28px"
          }
          }}/>
          <QuestionItemCollectionFifth
            overrideItems={({ item, index}) => ({
              style: { cursor: "pointer"},
              onClick: () => {
                setPageNo(item.SortNumber);
                setX(item.SortNumber);
                setAnsNo5(item.SortNumber);
              }
            })}/>
        </>
        )}
        {showQuestion6 && (
         <>
          <QuestionItemHead
          
        overrides={{
          "text39455121": {
            children:"Q6 このアプリに求めることは何ですか",
            fontSize:"28px"
          }
          }}/>
          <QuestionItemCollectionSixth
          
             overrideItems={({ item, index}) => ({
              style: { cursor: "pointer"},
              onClick: () => {
                setPageNo(item.SortNumber);
                setX(item.SortNumber);
                setAnsNo6(item.SortNumber);
              }
            })}/>
          </>
        )}
        <QuestionItemFoot
          overrides={{
            "Frame 30": {
              onClick: showQuestion ? handleFrame30Click : showQuestion2 ? handleFrame30Click2 : showQuestion3 ? handleFrame30Click3 : showQuestion4 ? handleFrame30Click4 :showQuestion5 ? handleFrame30Click5:  handleFinalClick
            },    
            "Frame 31": {
              onClick: useNavigateAction({ type: "url", url: "/CheckStart" })
            },
            "text39455125": {
               children: "＜ 診断開始に戻る"
            },
            "text39455127": {
               children: showQuestion6 ? "診断結果へ ＞" : "次へ"
            }
          }}
        />
        </MyContext.Provider>
        </div>
        <div className="Footerfixed">
        <Footer />
        </div>
      
    </div>
  );
}

export default CheckQuestion;