import '../App.css';
// === Add the following ===
import { Amplify } from 'aws-amplify';
import { StaticHeader ,Footer} from '../ui-components';
//import Commentcomponent from '../ui-components/Commentcomponent';
//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

import { useSearchParams } from "react-router-dom";
import Comments from './Comments';

import '@aws-amplify/ui-react/styles.css'; // default theme


import {
  Card,
  Image,
  View,
  Heading,
  Flex,
  Badge,
  Text,
  Button,
  useTheme,
  ThemeProvider
} from '@aws-amplify/ui-react';


Amplify.configure(awsExports);
// ======
function CommunityList() {
    
  const { tokens } = useTheme();

;
  return (

    <>
    <div className="Headerfixed">
      <StaticHeader/>
    </div>
        
     <div className="contentmiddle">

    
        <View
      backgroundColor={tokens.colors.background.secondary}
      padding={tokens.space.medium}
    >
      <Card>
        <Flex direction="row" alignItems="flex-start">
          <Image
            alt="Road to milford sound"
            src="https://public-cafo.s3.ap-northeast-1.amazonaws.com/wether.jfif"
            width="33%"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            textAlign="left"
            gap={tokens.space.xs}
          >
            <Flex>
              <Badge size="small" variation="info">
                授業
              </Badge>
              <Badge size="small" variation="success">
                数学
              </Badge>
              <Badge size="small" variation="success">
                地学
              </Badge>
            </Flex>

            <Heading level={5}>
           数学を実世界の問題解決にどのように適用するかを考えよう   
            </Heading>

            <Text as="span">
              2年生の数学の授業で、統計学を用いて地域の気候変動を分析するプロジェクト
            </Text>

            <Button variation="primary">参加する</Button>
          </Flex>
        </Flex>
      </Card>
      
      <Card>
        <Flex direction="row" alignItems="flex-start">
          <Image
            alt="Road to milford sound"
            src="https://public-cafo.s3.ap-northeast-1.amazonaws.com/Trump.jfif"
            width="33%"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            textAlign="left"
            gap={tokens.space.xs}
          >
            <Flex>
              <Badge size="small" variation="info">
                授業
              </Badge>
              <Badge size="small" variation="success">
                数学
              </Badge>
            </Flex>

            <Heading level={5}>
           確率をゲームを学んでみよう
            </Heading>

            <Text as="span">
            2年生の数学の授業で、確率を楽しく、実際に体感しながら学ぶためのカードゲームを導入しませんか
            </Text>

            <Button variation="primary">参加する</Button>
          </Flex>
        </Flex>
      </Card>
      
  
        <Card>
        <Flex direction="row" alignItems="flex-start">
          <Image
            alt="Road to milford sound"
            src="https://public-cafo.s3.ap-northeast-1.amazonaws.com/vr.jfif"
            width="33%"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            textAlign="left"
            gap={tokens.space.xs}
          >
            <Flex>
              <Badge size="small" variation="info">
                研究活動
              </Badge>
            </Flex>

            <Heading level={5}>
           授業方法のイノベーション
            </Heading>

            <Text as="span">
            生物の授業で、VR技術を使用して細胞の構造を視覚的に学習してみませんか
            </Text>

            <Button variation="primary">参加する</Button>
          </Flex>
        </Flex>
      </Card>
      
      
    </View>
    
    
    
     </div>

      <div className="Footerfixed">
      <Footer/>
      </div>
     < />
  );
}

export default CommunityList;