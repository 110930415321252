import '../App.css';
// === Add the following ===
import { Amplify } from 'aws-amplify';
import { StaticHeader ,ProductCard2,Footer} from '../ui-components';
import ProductCard2Collection_bk from '../ui-components/ProductCard2Collection_bk';

//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';


Amplify.configure(awsExports);
// ======
function Home() {
  return (
    <div className="Home">
    <div className="Headerfixed">
      <StaticHeader/>
       </div>
      
       <div className="contentmiddle">
          <ProductCard2Collection_bk />　
        </div>
      <div className="Footerfixed">
      <Footer/>
      </div>

        </div>
  );
}



export default Home;
